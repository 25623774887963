import {
  limit,
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  CircularProgress,
  Snackbar,
  Pagination,
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../config/firebase";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

type Prompt = {
  id: string;
  name: string;
  promptText: string;
  status: number;
  updatedAt: any;
};

export const PromptListPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchPrompts = () => {
      setLoading(true);
      const promptCollectionRef = collection(db, "prompts");
      const q = query(promptCollectionRef, orderBy("updatedAt", "desc"), limit(itemsPerPage));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) {
          console.log("プロンプトが見つかりません");
        } else {
          const newPrompts: Prompt[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            promptText: doc.data().promptText,
            status: doc.data().status,
            updatedAt: doc.data().updatedAt,
          }));
          setPrompts(newPrompts);
        }
        setLoading(false);
      });
      return () => unsubscribe();
    };

    fetchPrompts();
  }, [page]);

  const handleDeletePrompt = async (promptId: string) => {
    try {
      const promptRef = doc(db, "prompts", promptId);
      await deleteDoc(promptRef);
      setMessage("プロンプトを削除しました");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("プロンプトの削除に失敗しました", error);
      setMessage("プロンプトの削除に失敗しました");
      setSnackbarOpen(true);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: "80%", m: "auto", p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h5">{t("登録済みのプロンプト")}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate("/prompt/new")}
        >
          {t("新規登録")}
        </Button>
      </Stack>

      {loading && <CircularProgress />}

      <List>
        {prompts.map((prompt) => (
          <Box key={prompt.id}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={prompt.name}
                secondary={
                  <>
                    <Typography variant="body2" color="text.secondary" >
                      {prompt.promptText.substring(0, 50)}...
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("ステータス")}:
                      {(() => {
                        switch (prompt.status) {
                          case 1:
                            return "オープン";
                          case 2:
                            return "クローズ";
                          case 3:
                            return "削除済み";
                          case 4:
                            return "審査中";
                          default:
                            return "不明";
                        }
                      })()}
                    </Typography>
                  </>
                }
              />
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => navigate(`/prompt/edit/${prompt.id}`)}
                disabled={loading}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeletePrompt(prompt.id)}
                disabled={loading}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        ))}
      </List>

      <Pagination
        count={Math.ceil(prompts.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={message}
      />
    </Box>
  );
};
