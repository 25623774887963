import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Layout } from "./layouts/Layout";

import { TopPage } from "./pages/TopPage";
import { ArticleListPage } from "./pages/ArticleListPage";
import { ArticleNewPage } from "./pages/ArticleNewPage";
import { ArticleTrendNewPage } from "./pages/ArticleTrendNewPage";
import { BlogListPage } from "./pages/BlogListPage";
import { ImageSearchPage } from "./pages/ImageSearchPage";
import { TrendSearchPage } from "./pages/TrendSearchPage";
import { RagMakerPage } from "./pages/RagMakerPage";
// import { BlogNewPage } from "./pages/BlogNewPage";
import { FeedListPage } from "./pages/FeedListPage";
import { PromptListPage } from "./pages/PromptListPage";
import { PromptNewPage } from "./pages/PromptNewPage";
import { PromptEditPage } from "./pages/PromptEditPage";

import { LinebotListPage } from "./pages/LinebotListPage";
import { LinebotNewPage } from "./pages/LinebotNewPage";
import { LinebotEditPage } from "./pages/LinebotEditPage";

import { RagListPage } from "./pages/RagListPage";
import { RagNewPage } from "./pages/RagNewPage";
import { RagEditPage } from "./pages/RagEditPage";
import { FeedArticlePage } from "./pages/FeedArticlePage";
import {FeedNewPage} from "./pages/FeedNewPage";
import { SettingPlanPage } from "./pages/SettingPlanPage";


// import { SettingPasswordPage } from "./pages/SettingPasswordPage";
import { SettingNotificationDesktopPage } from "./pages/SettingNotificationDesktopPage";
import { SettingNotificationEmailPage } from "./pages/SettingNotificationEmailPage";
import ForgotPassword from './pages/ForgotPassword';
import {Login} from './pages/Login';
import Register from './pages/Register';
import Chat from './pages/Chat';
import ChatBot from './pages/ChatBot';
import Unsubscribe from './pages/Unsubscribe';
import {PrivacyPolicyPage} from './pages/PrivacyPolicy';
import {TermOfServicePage} from './pages/TermOfService';
export const AppRoutes: FC = () => {



  return (
 
      <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<ArticleListPage />} />
        <Route path="/articles" element={<ArticleListPage />} />
        <Route path="/trends" element={<TrendSearchPage />} />
        <Route path="/ragmaker" element={<RagMakerPage />} />
        {/* <Route path="/article/new" element={<TopPage />} /> */}
        <Route path="/article/new" element={<ArticleNewPage />} />
        <Route path="/article_trend/new" element={<ArticleTrendNewPage />} />
        <Route path="/blogs" element={<BlogListPage />} />
        {/* <Route path="/blog/new" element={<BlogNewPage />} /> */}
        <Route path="/feed/new" element={<FeedNewPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chatbot" element={<ChatBot />} />
        <Route path="/prompt/new" element={<PromptNewPage />} />
        <Route path="/prompt/edit/:id" element={<PromptEditPage />} />
        <Route path="/linebots" element={<LinebotListPage />} />
        <Route path="/linebot/new" element={<LinebotNewPage />} />
        <Route path="/linebot/edit/:id" element={<LinebotEditPage />} />

        <Route path="/prompts" element={<PromptListPage />} />
        <Route path="/rag/new" element={<RagNewPage />} />
        <Route path="/rag/edit/:id" element={<RagEditPage />} />
        <Route path="/rags" element={<RagListPage />} />
        <Route path="/feeds_article" element={<FeedArticlePage />} />
        <Route path="/images" element={<ImageSearchPage />} />
        <Route path="/settings/plan" element={<SettingPlanPage />} />
        <Route path="/settings/login" element={<Login />} />
        <Route path="/settings/register" element={<Register />} />
        <Route path="/settings/forgot-password" element={<ForgotPassword />} />
        <Route path="/settings/unsubscribe" element={<Unsubscribe />} />
        <Route path="/rule/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/rule/term-of-service" element={<TermOfServicePage />} />
      </Route>
    </Routes> 

  );
};
