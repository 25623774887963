import { FC, useState, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore"; // これを追加
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";

type Form = {
  name: string;
  memo: string;
  ragId: string;
  promptId: string;
  isOpen: boolean;
};

export const LinebotEditPage: FC = () => {
  const { id } = useParams<{ id: string }>(); // ルートパラメータからIDを取得
  const navigate = useNavigate();
  const { userId } = useAppContext();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<Form>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [rags, setRags] = useState<any[]>([]);
  const [prompts, setPrompts] = useState<any[]>([]);

  // 初期データ取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          throw new Error("編集対象のIDが指定されていません");
        }

        // Linebotの既存データを取得
        const docRef = doc(db, "linebots", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error("編集対象のデータが存在しません");
        }

        const linebotData = docSnap.data();
        setValue("name", linebotData.name || "");
        setValue("memo", linebotData.memo || "");
        setValue("ragId", linebotData.ragId || "");
        setValue("promptId", linebotData.promptId || "");
        setValue("isOpen", linebotData.isOpen || false);

        // RAGとPromptのデータを取得
        const ragsRef = collection(db, "rags");
        const promptsRef = collection(db, "prompts");

        const userRagQuery = query(ragsRef, where("userId", "==", userId));
        const userPromptQuery = query(promptsRef, where("userId", "==", userId), where("type", "==", "chat"));

        const openRagQuery = query(ragsRef, where("isOpen", "==", true), where("type", "==", "chat"));
        const openPromptQuery = query(promptsRef, where("isOpen", "==", true), where("type", "==", "chat"));

        const [userRagSnap, userPromptSnap, openRagSnap, openPromptSnap] = await Promise.all([
          getDocs(userRagQuery),
          getDocs(userPromptQuery),
          getDocs(openRagQuery),
          getDocs(openPromptQuery),
        ]);

        const fetchedRags = [
          ...userRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];
        const fetchedPrompts = [
          ...userPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];

        setRags(fetchedRags);
        setPrompts(fetchedPrompts);
      } catch (error) {
        console.error("データの取得に失敗しました", error);
        setErrorMessage("データの取得中にエラーが発生しました。再試行してください。");
      }
    };

    fetchData();
  }, [id, userId, setValue]);

  const submit = async (data: Form) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      if (!id) {
        throw new Error("編集対象のIDが指定されていません");
      }

      const docRef = doc(db, "linebots", id);
      await updateDoc(docRef, {
        name: data.name,
        memo: data.memo,
        ragId: data.ragId,
        promptId: data.promptId,
        isOpen: data.isOpen,
        updatedAt: serverTimestamp(),
      });

      navigate("/linebots"); // 更新後に一覧ページへ移動
    } catch (error) {
      console.error("Linebotの更新に失敗しました", error);
      setErrorMessage("更新中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{
        p: 5,
        mt: 4,
        mx: "auto",
        maxWidth: "600px",
        border: "1px dashed grey",
        borderRadius: 2,
        backgroundColor: "#f7f7f7",
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          AI Line Botを編集
        </Typography>

        {/* Line bot名 */}
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Line bot名は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Line bot名"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />

        {/* メモ */}
        <Controller
          name="memo"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="メモ (任意)"
              fullWidth
              multiline
              rows={3}
            />
          )}
        />

        {/* RAG選択 */}
        <Controller
          name="ragId"
          control={control}
          defaultValue=""
          rules={{ required: "RAGの選択は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="関連付けるRAGを選択"
              fullWidth
              error={!!errors.ragId}
              helperText={errors.ragId?.message}
            >
              {rags.map((rag) => (
                <MenuItem key={rag.id} value={rag.id}>
                  {rag.name || "名前未設定"} - {rag.memo || "メモなし"}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        {/* Prompt選択 */}
        <Controller
          name="promptId"
          control={control}
          defaultValue=""
          rules={{ required: "Promptの選択は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="関連付けるPromptを選択"
              fullWidth
              error={!!errors.promptId}
              helperText={errors.promptId?.message}
            >
              {prompts.map((prompt) => (
                <MenuItem key={prompt.id} value={prompt.id}>
                  {prompt.name || "名前未設定"} - {prompt.memo || "メモなし"}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        {/* 公開設定 */}
        <Controller
          name="isOpen"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} />}
              label="公開設定（ON: 一般公開）"
            />
          )}
        />

        {/* エラーメッセージ */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        {/* 登録ボタン */}
        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : "更新する"}
        </Button>
      </Stack>
    </Box>
  );
};
